import './location-marquee.scss';
import { gsap } from 'gsap/dist/gsap';

class LocationMarquee {
    constructor($element) {
        this.$locationMarqueeRoot = $element;

        this.$locationMarqueeWrapper =
            this.$locationMarqueeRoot.querySelectorAll(
                '.location-marquee__items-wrapper'
            );

        this.$locationMarqueeWrapper.forEach((el) => {
            const marquee = el.querySelector('.location-marquee__items');

            const rate = 80; // the higher the value, the faster it is
            const distance = marquee.clientWidth;
            const style = window.getComputedStyle(marquee);
            const marginRight = parseInt(style.marginRight) || 0;
            const totalDistance = distance + marginRight;

            const time = totalDistance / rate;
            const container = marquee.parentElement;

            const isLeft = marquee.dataset.direction === 'l';

            gsap.to(container, time, {
                repeat: -1,
                x: (isLeft ? '-' : '') + totalDistance,
                ease: 'linear',
                delay: isLeft ? 0 : 1,
                autoAlpha: 1
            });
        });
    }
}

export { LocationMarquee };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $locationMarquees = $context.querySelectorAll(
            '[data-location-marquee="root"]'
        );

        if ($locationMarquees.length > 0) {
            for (let i = 0; i < $locationMarquees.length; i++) {
                new LocationMarquee($locationMarquees[i]);
            }
        }
    }
});
